<template>
<div class="m-auto mx-4 p-4 my-4 mt-0 pt-0 flex-grow">

  <h1 class="mb-6 text-xl text-center text-gray-600">بيانات شخصية</h1>
  
  <div class="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

    <div class="sm:col-span-2">
      <label for="country" class="block text-sm font-medium text-gray-700">
        نوع الفيزا
      </label>
      <div class="mt-1">
        <select id="country" name="country" autocomplete="country-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
        </select>
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="country" class="block text-sm font-medium text-gray-700">
        المحافظة
      </label>
      <div class="mt-1">
        <select id="country" name="country" autocomplete="country-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
        </select>
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="country" class="block text-sm font-medium text-gray-700">
        جهة الفحص
      </label>
      <div class="mt-1">
        <select id="country" name="country" autocomplete="country-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
        </select>
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="first-name" class="block text-sm font-medium text-gray-700">
        رقم الباسبور
      </label>
      <div class="mt-1">
        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="first-name" class="block text-sm font-medium text-gray-700">
        الاسم الأول
      </label>
      <div class="mt-1">
        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="last-name" class="block text-sm font-medium text-gray-700">
        اسم الأب
      </label>
      <div class="mt-1">
        <input type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="first-name" class="block text-sm font-medium text-gray-700">
        اسم الجد
      </label>
      <div class="mt-1">
        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="first-name" class="block text-sm font-medium text-gray-700">
        اسم العائلة
      </label>
      <div class="mt-1">
        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="first-name" class="block text-sm font-medium text-gray-700">
        تاريخ الميلاد
      </label>
      <div class="mt-1">
        <input type="date" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label for="country" class="block text-sm font-medium text-gray-700">
        الجنسية
      </label>
      <div class="mt-1">
        <select id="country" name="country" autocomplete="country-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
        </select>
      </div>
    </div>

    <div class="mt-4 space-y-4 flex">
      <!-- <div class="flex items-center">
        <input id="push-email" name="push-notifications" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
        <label for="push-email" class="ml-3 block text-sm font-medium text-gray-700">
          ذكر
        </label>
      </div> -->
      <div class="flex items-center mr-4 pt-4">
        <input id="male" name="gender" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
        <label for="male" class="ml-3 block text-sm font-medium text-gray-700">
          ذكر
        </label>
      </div>
      <div class="flex items-center">
        <input id="female" name="gender" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
        <label for="female" class="ml-3 block text-sm font-medium text-gray-700">
          أنثى
        </label>
      </div>
    </div>

  </div>
</div>
</template>

<script>

export default {
  
}
</script>

<style>

</style>